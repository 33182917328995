// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
// import Popper from "popper.js";

// to install plugin to webpack:
// - npm install moment
// - add plugin in webpack.config.js
// - add here window.moment = require('moment');

window.AOS = require("aos");
window.slick = require("slick-slider");
window.jQuery = $;
window.$ = $;

require("bootstrap");
require("../src/js/modules/lightbox");

// Smooth scrolling using jQuery easing
$('a.js-scroll-trigger[href*="#"]:not([href="#"])').on("click", function () {
  if (location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
    if (target.length) {
      $("html, body").animate(
        {
          scrollTop: target.offset().top - 72
        },
        1000,
        "easeInOutExpo"
      );
      return false;
    }
  }
});

$(".js-scroll-trigger").on("click", function () {
  $(".navbar-collapse").removeClass("show");
});

// Add white background if menu clicked from top
$(".navbar-toggler").on("click", function () {
  $("#mainNav").addClass("navbar-clicked");
});

// Add class to Nav if scrolled
$(window).on("scroll", function () {
  if ($("#mainNav").offset().top > 100) {
    $("#mainNav").addClass("navbar-scrolled");
  } else {
    $("#mainNav").removeClass("navbar-scrolled");
  }
});

// Scroll spy - Add active class to scrolled menu section
$("body").scrollspy({
  target: "#mainNav",
  offset: 200
});

// Funkcia nastavi cookie
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + 3 * 30 * 24 * 3600000); // cookie expiration (pocet milisekund) 3 months
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// Funkcia na zistenie cookie
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// Cookies bar
$(document).ready(function () {
  if (getCookie("cookies") == "clicked") {
    $("#cookie-dialog").addClass("d-none");
  } else {
    $("#cookie-dialog").removeClass("d-none");
  }

  // hide cookie listu on click
  $("#cookie-button").on("click", function () {
    setCookie("cookies", "clicked", 1);
    $("#cookie-dialog").addClass("d-none");
  });
});

// Main initialization of AOS
$(window).on("load", function () {
  AOS.init({
    once: true
  });
});

// Set language to localStorage
$(document).ready(function () {
  // Set default lang
  var currLang = localStorage.getItem("lang");
  if (!currLang) {
    localStorage.setItem("lang", "sk");
  }

  // Language picker toggler
  $(".lang-picker").on("click", function () {
    let lang = $(this).attr("id");
    if (currLang != lang) {
      localStorage.setItem("lang", lang);
    }
  });
});

$(".reviews-slick").slick({
  infinite: true,
  autoplay: true,
  arrows: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    },
  ]
});

$(".header-slick").slick({
  infinite: true,
  autoplay: true,
  arrows: false,
  fade: true,
  cssEase: 'linear',
  slidesToShow: 1,
  slidesToScroll: 1,
});

$(".page-slick").slick({
  infinite: true,
  arrows: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    },
  ]
});

$(".btn__booking").on("click", function () {
  let arrival = $("#date_arrival").val();
  let departure = $("#date_departure").val();
  let guests = $("#num_guests").val();

  $("#hidden_arrival").val(arrival);
  $("#hidden_departure").val(departure);
  $("#hidden_guests").val(guests);
});

$(".php__btn-send").on("click", function () {
  $('#contactModal').modal('hide')
  $(".contact--send").toggleClass("d-none"); 
});

$(".contact--btn").on("click", function () {
  $(".contact--send").toggleClass("d-none");
});

